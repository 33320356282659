import * as Repository from "./cycle.repository";
import { CycleDTO, CreateCycleDTO } from "./cycle.dto";

async function getCycleById(cycleId: string): Promise<CycleDTO> {
  const cycle: CycleDTO = await Repository.findById(cycleId);

  return cycle;
}

async function getCycles(): Promise<CycleDTO[]> {
  const cycles: CycleDTO[] = await Repository.findAllCycles();

  return cycles;
}

async function getActiveCycle(): Promise<CycleDTO> {
  const cycle: CycleDTO = await Repository.getActiveCycle();
  return cycle;
}

async function createCycle(cycleToCreate: CreateCycleDTO): Promise<CycleDTO> {
  const cycle: CycleDTO = await Repository.createCycle(cycleToCreate);

  return cycle;
}

async function updateCycle(
  cycleId: string,
  isActive: boolean
): Promise<CycleDTO> {
  const cycle: CycleDTO = await Repository.updateCycle(cycleId, isActive);

  return cycle;
}

export const CycleService = {
  getCycles,
  updateCycle,
  createCycle,
  getCycleById,
  getActiveCycle
};
