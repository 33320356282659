import { Get, Post, Patch } from "../Repository";

import * as API from "./cycle.api";
import { CycleDTO, CreateCycleDTO } from "./cycle.dto";

async function findById(cycleId: string): Promise<CycleDTO> {
  const response = await Get(`${API.route}/${cycleId}`);

  const cycle: CycleDTO = response as CycleDTO;

  return cycle;
}

async function findAllCycles(): Promise<CycleDTO[]> {
  const response = await Get(API.route);

  const cycles: CycleDTO[] = response as CycleDTO[];

  return cycles;
}

async function createCycle(cycleObject: CreateCycleDTO): Promise<CycleDTO> {
  const response = await Post(API.route, cycleObject);

  const cycle: CycleDTO = response as CycleDTO;

  return cycle;
}

async function getActiveCycle(): Promise<CycleDTO> {
  const response = await Get(API.getActiveCycle);

  const cycle: CycleDTO = response as CycleDTO;

  return cycle;
}

async function updateCycle(
  cycleId: string,
  isActive: boolean
): Promise<CycleDTO> {
  const response = await Patch(`${API.route}/${cycleId}`, { isActive });

  const cycle: CycleDTO = response as CycleDTO;

  return cycle;
}

export { createCycle, findById, findAllCycles, getActiveCycle, updateCycle };
