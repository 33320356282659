

import { Vue, Component } from "vue-property-decorator";

import { CycleService } from "@/services/cycle";
import { CycleDTO } from "@/services/cycle/cycle.dto";

import liveData from "@/store/Modules/liveData";
import CycleStore from "@/services/cycle/cycle.store";

@Component
export default class Home extends Vue {
  liveData!: any;
  logoutDialog = false;

  cycle!: any;

  // data() {
  //   return {
  //     logoutDialog: false
  //   };
  // }

  created() {
    console.log("Hello");
    const stringCycle = window.localStorage.getItem("currentCycle");
    if (stringCycle) {
      const currentCycle = JSON.parse(stringCycle);
      this.$store.commit("CycleStore/setCycle", currentCycle);
    } else {
      this.getActiveCycle();
    }
    this.cycle = CycleStore;
    this.liveData = liveData;
    // if (!this.cycle.state.cycleId) {
    //   this.getActiveCycle();
    // } else {
    //   this.goToDashboard();
    // }
  }

  async getActiveCycle() {
    const activeCycle: CycleDTO = await CycleService.getActiveCycle();
    this.$store.commit("CycleStore/setCycle", activeCycle);
    window.localStorage.setItem("currentCycle", JSON.stringify(activeCycle));
    this.goToDashboard();
  }

  goToDashboard() {
    this.$router.push("/dashboard");
  }

  goToEmployees() {
    this.$router.push("/employees");
  }

  goToCustomers() {
    this.$router.push("/groups");
  }

  goToExportFiles() {
    this.$router.push("/report");
  }

  goToCycles() {
    this.$router.push("/cycles");
  }

  goToSearch() {
    this.$router.push("/search");
  }

  logout() {
    console.log("Hello World!");
  }
}
